import { localeEn as leapPagesEn } from '-/leap-pages';
import { localeEn as leapStoriesEn } from '-/leap-stories';

export default {
  landingPage: {
    buyFor: 'Buy for ',
    emailCapture: {
      caption: 'Enter your email to get this for free',
      captionForMailList: 'Enter your email to sign up',
      cta: 'Sign me up',
    },
    payWhatYouWant: {
      caption: 'Enter the amount you want to pay',
      cta: 'Buy',
    },
    agreements: {
      claim: 'By signing up, you agree to our',
      privacyPolicy: 'privacy policy',
      termsOfService: 'terms of service',
      and: 'and',
    },
    purchaseNotAvailable: 'This product is currently unavailable.',
    emailCaptureNotAvailable: 'This product is currently unavailable.',
    coaching: {
      footer: {
        ONE_TIME_PAYMENT: {
          title: 'Book for {{price}}',
        },
        EMAIL_CAPTURE: {
          title: 'Book for free',
        },
        PAY_WHAT_YOU_WANT: {
          title: 'Pay what you want',
        },
      },
      landingPage: {
        button: 'Select a date',
      },
      errors: {
        missingDate:
          'Please select a date to book. If no dates are available, {{handle}} may be fully booked out. Please try again later.',
        missingTime: 'Please select a time slot to reserve.',
      },
      calendar: {
        selectDate: 'Select a date',
        button: 'Select a time',
        caption: 'Pick an available date to continue',
      },
      slots: {
        changeDate: 'Change date',
        button: 'Proceed to checkout',
        timezone: 'Timezone: {{timezone}}',
        slotsCaption: 'Select a time:',
        slotsDirections:
          'Selected time is reserved for 30 mins to complete checkout',
        slotLabel: '{{start}} - {{end}} ({{timezone}})',
        additionalInfo:
          'Please share any information that will help me prepare for the session:',
      },
    },
  },
  myStore: {
    poweredBy: 'Powered by',
    theLeap: 'The Leap',
    openLink: 'Open link',
    getItFree: 'Get it free',
    buyFor: 'Buy for',
    payWhatYouWant: 'Pay what you want',
    emptyStateTitle: 'Your storefront is looking little empty 👻',
    emptyStateDescription:
      'Once you have created a product and set the status to live, it will appear here.',
    signUpForFree: 'Sign up for free',
    plgFooter: 'Create your own store with',
    emailCapture: {
      signup: 'Sign up now',
      learnMore: 'Learn more',
    },
    status: {
      anonymous: {
        PENDING_EMAIL_CONFIRMATION: {
          title: "Oops, this store isn't available yet 👻",
          description:
            'If you are the owner, please sign in to unlock your store.',
        },
        DEACTIVATED: {
          title: 'Page no longer exists 🫥',
          description:
            "This might be because the content has expired, was outdated, needed to make room for something new, or perhaps it didn't align with our ",
          guidingPrinciples:
            "We're dedicated to maintaining a respectful and safe environment for our community and committed to ensuring quality and integrity on our platform.",
          contentPolicies: 'content policies.',
          support: 'If you believe we made a mistake, please ',
          contactUs: 'contact us.',
        },
      },
      creator: {
        PENDING_EMAIL_CONFIRMATION: {
          title: 'Almost there... 🤩',
          description:
            'To unlock your store, please confirm your email address via the email we sent after registration.',
          support:
            "Can't find the email? Please check your spam folder, or contact us.",
          contactUs: 'contact us.',
        },
        DEACTIVATED: {
          title: 'Page no longer exists 🫥',
          description:
            "This might be because the content has expired, was outdated, needed to make room for something new, or perhaps it didn't align with our ",
          guidingPrinciples:
            "We're dedicated to maintaining a respectful and safe environment for our community and committed to ensuring quality and integrity on our platform.",
          contentPolicies: 'content policies.',
          support: 'If you believe we made a mistake, please ',
          contactUs: 'contact us.',
        },
      },
    },
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    contactEmail: 'contact us at',
    contactAddress: 'or by mail at',
    companyAddress: '400-369 Terminal Ave, Vancouver, BC V6A 4C4',
    section1Heading:
      '1. What information do we collect and what do we do with it?',
    section1Body1:
      'When you subscribe to our site to receive free or paid products, we collect the personal information you give us such as your name and email address.',
    section1Body2:
      'Email marketing: we may send you emails about our site, related products or other updates. We may also use your email to inform you about new products, services, changes to products, survey you about your usage, or collect your opinion.',
    section2Heading: '2. How do you get my consent?',
    section2Body1:
      'When you provide us with personal information to subscribe to a service on our site, make a purchase, or participate or consume a digital product you imply that you consent to our collecting it and using it for that specific reason only.',
    section2Body2:
      'If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to unsubscribe if consent is implied.',
    section2Subheading1: 'How do I withdraw my consent?',
    section2Subsection1Contact:
      'If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime,',
    section3Heading: '3. Disclosure',
    section3Body:
      'We may disclose your personal information if we are required by law to do so.',
    section4Heading: '4. The Leap',
    section4Body1:
      'Our site is hosted by The Leap. They provide us with the online platform that allows us to sell our product/services to you.',
    section4Body2:
      "Your data is stored through The Leap's data storage, databases and the general The Leap application. The Leap employs technical safeguards to ensure the security of your data.",
    section4Body3:
      'By subscribing to our service, you grant The Leap consent to send transactional and marketing communications in the future.',
    section4Body4:
      'If after you opt-in, you change your mind, you may withdraw your consent for The Leap to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting The Leap at',
    section4Subheading1: 'Payment',
    section4Subsection1Body1:
      'If you make a purchase on our site, we use a third-party payment processor such as Stripe or PayPal. Payments are encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction.',
    section4Subsection1Body2:
      'All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.',
    section4Subsection1Body3:
      'PCI-DSS requirements help ensure the secure handling of credit card information by our site and related courses and its service providers.',
    section4Subsection1Body4:
      "For more insight, you may also want to read The Leap's",
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    or: 'or',
    section5Heading: '5. Third Party Services',
    section5Body1:
      'In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.',
    section5Body2:
      'However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.',
    section5Body3:
      'For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.',
    section5Body4:
      'Certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. If you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.',
    section5Body5:
      'As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.',
    section5Body6:
      "Once you leave our website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service.",
    section5Subheading1: 'Links',
    section5Subsection1Body1:
      'When you click on links on our site, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.',
    section6Heading: '6. Security',
    section6Body1:
      'To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.',
    section6Body2:
      'If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with AES-256 encryption.  Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.',
    section6Subheading1: 'Cookies',
    section6Subsection1Body1:
      "The Leap collects cookies or similar tracking technologies. This means information that our website's server transfers to your computer. This information can be used to track your session on our website. Cookies may also be used to customize our website content for you as an individual. If you are using one of the common Internet web browsers, you can set up your browser to either let you know when you receive a cookie or to deny cookie access to your computer.",
    section6Subsection1Body2:
      'The Leap uses cookies to recognize your device and provide you with a personalized experience.',
    section6Subsection1Body3:
      'We may also use automated tracking methods on our websites, in communications with you, and in our products and services, to measure performance and engagement.',
    section6Subsection1Body4:
      'Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.',
    section6Subheading2: 'Web Analysis Tools',
    section6Subsection2Body1:
      'We may use web analysis tools that are built into the website to measure and collect anonymous session information.',
    section7Heading: '7. Age of Consent',
    section7Body1:
      'By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence.',
    section8Heading: '8. Changes to this Privacy Policy',
    section8Body1:
      'We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.',
    section8Body2:
      'If our site or course is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.',
    section9Heading: 'Questions and contact information',
    section9Contact:
      'If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information',
  },
  termsOfService: {
    title: 'Terms of Service',
    contactEmail: 'contact us at',
    contactAddress: 'or by mail at',
    companyAddress: '400-369 Terminal Ave, Vancouver, BC V6A 4C4',
    overviewHeading: 'Overview',
    overviewBody1:
      'This website is operated by {{name}}. Throughout the site, the terms “we”, “us” and “our” refer to {{name}}. {{name}} offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.',
    overviewBody2:
      'By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.',
    overviewBody3:
      'Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.',
    overviewBody4:
      'Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.',
    overviewBody5:
      'Our store is hosted on The Leap. They provide us with the online e-commerce platform that allows us to sell our products and services to you.',
    section1Heading: 'Section 1 - Online store terms',
    section1Body1:
      'By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence, and you have given us your consent to allow any of your minor dependents to use this site.',
    section1Body2:
      'You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).',
    section1Body3:
      'You must not transmit any worms or viruses or any code of a destructive nature.',
    section1Body4:
      'A breach or violation of any of the Terms will result in an immediate termination of your Services.',
    section2Heading: 'Section 2 - General conditions',
    section2Body1:
      'We reserve the right to refuse service to anyone for any reason at any time.',
    section2Body2:
      'You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.',
    section2Body3:
      'You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.',
    section2Body4:
      'The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.',
    section3Heading:
      'Section 3 - Accuracy, completeness and timeliness of information',
    section3Body1:
      'We are not responsible if information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this site is at your own risk.',
    section3Body2:
      'This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.',
    section4Heading: 'Section 4 - Modifications to the service and prices',
    section4Body1:
      'Prices for our products are subject to change without notice.',
    section4Body2:
      'We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.',
    section4Body3:
      'We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    or: 'or',
    section5Heading: 'Section 5 - Products or services',
    section5SubHeading: '(if applicable)',
    section5Body1:
      'In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.',
    section5Body2:
      'However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.',
    section5Body3:
      'For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.',
    section5Body4:
      'Certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. If you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.',
    section5Body5:
      'As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.',
    section5Body6:
      "Once you leave our website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service.",
    section6Heading: 'Section 6 - Accuracy of billing and account information',
    section6Body1:
      'We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.',
    section6Body2:
      'You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.',
    section7Heading: 'Section 7 - Optional tools',
    section7Body1:
      'We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.',
    section7Body2:
      'You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.',
    section7Body3:
      'Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).',
    section7Body4:
      'We may also, in the future, offer new Services and/or features through the website (including, the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of Service.',
    section8Heading: 'Section 8 - Third party links',
    section8Body1:
      'Certain content, products and Services available via our Service may include materials from third-parties.',
    section8Body2:
      'Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.',
    section8Body3:
      "We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.",
    section9Heading:
      'Section 9 - User comments, feedback and other submissions',
    section9Body1:
      "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.",
    section9Body2:
      "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service.",
    section9Body3:
      'You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.',
    section10Heading: 'Section 10 - Personal information',
    section10Body1:
      'Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy, please see',
    section11Heading: 'Section 11 - Errors, inaccuracies and omissions',
    section11Body1:
      'Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).',
    section11Body2:
      'We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.',
    section12Heading: 'Section 12 - Prohibited Uses',
    section12Body1:
      'In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:',
    section12Subsection1ListItem1: '(a) for any unlawful purpose;',
    section12Subsection1ListItem2:
      '(b) to solicit others to perform or participate in any unlawful acts;',
    section12Subsection1ListItem3:
      '(c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;',
    section12Subsection1ListItem4:
      '(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others;',
    section12Subsection1ListItem5:
      '(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;',
    section12Subsection1ListItem6:
      '(f) to submit false or misleading information;',
    section12Subsection1ListItem7:
      '(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;',
    section12Subsection1ListItem8:
      '(h) to collect or track the personal information of others;',
    section12Subsection1ListItem9:
      '(i) to spam, phish, pharm, pretext, spider, crawl, or scrape;',
    section12Subsection1ListItem10:
      '(j) for any obscene or immoral purpose; or',
    section12Subsection1ListItem11:
      '(k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.',
    section13Heading:
      'Section 13 - Disclaimer of warranties; limitation of liability',
    section13Body1:
      'We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.',
    section13Body2:
      'We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.',
    section13Body3:
      'You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.',
    section13Body4:
      'You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided “as is” and “as available” for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.',
    section13Body5:
      'In no case shall {{name}}, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility.',
    section13Body6:
      'Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.',
    section14Heading: 'Section 14 - Indemnification',
    section14Body1:
      'You agree to indemnify, defend and hold harmless {{name}} and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.',
    section15Heading: 'Section 15 - Severability',
    section15Body1:
      'In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.',
    section16Heading: 'Section 16 - Termination',
    section16Body1:
      'The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.',
    section16Body2:
      'These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.',
    section16Body3:
      'If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).',
    section17Heading: 'Section 17 - Entire agreement',
    section17Body1:
      'The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.',
    section17Body2:
      'These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).',
    section17Body3:
      'Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.',
    section18Heading: 'Section 18 - Governing law',
    section18Body1:
      'These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of {{location}}.',
    section19Heading: 'Section 19 - Changes to terms of service',
    section19Body1:
      'You can review the most current version of the Terms of Service at any time at this page.',
    section19Body2:
      'We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.',
    section20Heading: 'Section 20 - Contact information',
    section20Body1:
      'Questions about the Terms of Service should be sent to us at',
    section21Heading: 'Section 21 - No refund clause',
    section21Body1:
      'We do not offer refunds for any products or services purchased through our website unless explicitly stated otherwise. All sales are final.',
  },
  productAccess: {
    title: 'Oops! Looks like your link expired.',
    subtitle: 'Fill out the form below to regain access.',
    cta: 'Get my new link',
    emailField: 'Email',
  },
  thankYou: {
    default: {
      heading: 'Thanks for your order!',
      message: 'Head to your inbox to access your product.',
    },
    bookMyTime: {
      heading: 'Thanks for your request!',
      message:
        'I’ll be in touch soon via email with your meeting location details.',
    },
    coaching: {
      heading: 'Thank you for your request!',
      message:
        'Head to your inbox to access the booking form and choose your preferred date and time.',
    },
    askMeAnything: {
      heading: 'Thanks for your order!',
      message: "I'll be in touch soon with a response to your question.",
    },
    subscribe: {
      heading: 'Thanks for signing up!',
      message: 'Please head to your inbox now to confirm.',
    },
    confirmSubscription: {
      title: 'Confirmed!',
      message: 'Stay tuned for updates...',
    },
    done: 'Back to store',
    back: 'Back',
    createAndSellProducts: 'Create and sell your own products with',
    poweredBy: 'Powered by',
  },
  unsubscribe: {
    back: 'Back',
    unsubscribe: 'Unsubscribe',
    areYouSure: 'Are you sure you want to unsubscribe?',
    explanation:
      'By clicking Unsubscribe, you will no longer receive marketing emails from {{handle}}',
    sorryToSeeYouGo: "I'm sorry to see you go!",
    farewell:
      "I understand inboxes can fill up quickly, so you're now removed from my mailing list.",
    madeAMistake:
      'Unsubscribed by mistake? You can re-subscribe any time via my store 🙂',
    contactAlreadyUnsubscribed:
      'This contact was either already unsubscribred or does not exist. If you keep receiving emails, please contact us.',
    errorUnsubscribing: 'There was an error unsubscribing.',
    visitStore: 'Visit store',
  },
  productThankYou: {
    back: 'Back',
    done: 'Back to store',
    EMAIL_CAPTURE: {
      title: 'Confirmed!',
      message: 'Stay tuned for updates...',
    },
    ASK_ME_ANYTHING: {
      title: 'Confirmed!',
      message: "I'll be in touch soon with a response to your question.",
    },
    createAndSellProducts: 'Create and sell your own products with',
    poweredBy: 'Powered by',
  },
  ...leapStoriesEn,
  ...leapPagesEn,
  download: {
    cta: 'Download',
  },
};
